import React from "react";
import Layout from "../components/Layout/Layout";

export const FilmsTemplate = ({}) => <div className="content">FILMS</div>;

const Films = () => {
  return (
    <Layout>
      <FilmsTemplate />
    </Layout>
  );
};

export default Films;
